<template>
  <div class="nav_carousel_wrap">
    <el-carousel
      ref="carousel"
      trigger="click"
      indicator-position="none"
      :height="imgHeight + 'px'"
      :autoplay="false"
      @change="(index) => (active = index)"
    >
      <el-carousel-item v-for="(item, i) in carousels" :key="i">
        <img ref="image" :src="item" @click="handleClick(i,item)" />
      </el-carousel-item>
    </el-carousel>

    <div class="indicator__wrap">
      <span
        v-for="(item, i) in carousels"
        :key="i"
        class="indicator"
        :class="{ active: i === active }"
        @click="$refs.carousel.setActiveItem(i)"
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavCarousel",
  props: {
    carousels: {
      type: Array,
      default: () => [
        "https://img2.baidu.com/it/u=4106034698,601753042&fm=26&fmt=auto&gp=0.jpg",
        "https://img1.baidu.com/it/u=2748195236,657657476&fm=26&fmt=auto&gp=0.jpg",
        "https://img0.baidu.com/it/u=568816818,228314073&fm=26&fmt=auto&gp=0.jpg",
      ],
    },
  },
  data() {
    return {
      active: 0,
      imgHeight: "",
    };
  },
  methods: {
    handleClick(i,item) {
        console.log("/////****",item);
      this.$emit("on-click", i);
    },
    watchWindowResize() {
      this.imgHeight = this.$refs.image[0].height;
    }
  },
  mounted() {
    console.log(this.carousels,"vvvvv",this.$refs.image);
    this.$refs.image[0].onload = () => {
      this.imgHeight = this.$refs.image[0].height;
    };
    // 监听窗口变化，使得轮播图高度自适应图片高度
    window.addEventListener("resize", this.watchWindowResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.watchWindowResize);
  }
};
</script>
<style lang="scss" scoped>
$size: 0.75;
.indicator__wrap {
  position: absolute;
  left: 16%;
  bottom: 18%;
  .indicator {
    position: relative;
    display: inline-block;
    z-index: 10;
    width: #{$size}em;
    height: #{$size}em;
    border-radius: 50%;
    background: #bdc3c7;
    opacity: 0.5;
    cursor: pointer;
  }
  @for $i from 1 through 5 {
    $offset: $i * 1.5;
    .indicator:nth-child(#{$i}) {
      margin-right: #{$size * 1.5}em;
      &.active {
        border-radius: 0.5em;
        width: #{$size * 4}em;
        background: #fff;
        opacity: 1;
      }
    }
  }
}

.nav_carousel_wrap {
  width: 100vw;
  position: relative;
  /deep/ .el-carousel__container {
    overflow: hidden;
    .el-carousel__item {
      height: auto;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        cursor: pointer;
        // object-fit: fill;
      }
    }
    .el-carousel__arrow {
      width: auto;
      height: auto;
      font-size: 4rem;
      background: transparent;
    }
    .el-carousel__arrow:hover {
      background: transparent;
    }
  }
}
</style>