<template>
  <!--此部分根据设计自动生成，手动修改节点标记daltag="manual"属性，则该节点及子节点不会被自动生成代码覆盖，如发现其他需要调整框架情形请联系:alex.c@daline.com.cn-->
  <div id="Index">
    <header class="banner_wrap">
      <nav-swiper
        :carousels="navCarousels"
        @on-click="clickCarousel"
      ></nav-swiper>
    </header>
    <!-- S内容区 -->
    <main>
      <!-- /区域一 -->
      <section class="section1">
        <swiper class="swiper" ref="mySwiper" :options="swiperOption">
          <swiper-slide v-for="(carousel, i) in section1.carousels" :key="i">
            <div class="carousel-wrapper">
              <h4 class="carousel-title">{{ carousel.title }}</h4>
              <ul class="article-list">
                <li
                  class="article-item"
                  v-for="article in carousel.articleList"
                  :key="article.articleID"
                  @click="clicka8e7ac38(article)"
                >
                  <img :src="article.articleImg" class="article-image" />
                  <div class="article-title">
                    {{ article.articleTitle }}
                  </div>
                  <div class="article-content">
                    {{ article.articleSummary }}
                  </div>
                </li>
              </ul>
              <div class="more-article">
                <button class="btn dal-btn large" @click="carousel.navgation">
                  了解更多
                </button>
              </div>

              <transition name="fade">
                <div
                  class="arrow_button_left"
                  v-show="i === 0"
                  @click="swiper.slideTo(1, 1000, false)"
                >
                  <i class="el-icon-arrow-left"></i>
                </div>
              </transition>
              <transition name="fade">
                <div
                  class="arrow_button_right"
                  v-show="i === 1"
                  @click="swiper.slideTo(0, 1000, false)"
                >
                  <i class="el-icon-arrow-right"></i>
                </div>
              </transition>
            </div>
          </swiper-slide>
        </swiper>
      </section>

      <!-- /区域二 -->
      <section class="section2">
        <header>
          <h4>一体化业务开发平台</h4>
        </header>
        <!-- <main v-if="section2.carousels.length">
          <dal-comp-carousel :carouselData="section2.carousels"></dal-comp-carousel>
        </main> -->
        <div class="gallery-container">
          <Gallery :datasource="gallery.slice(0,2)" ref="child1" @childchange="child1change"></Gallery>
        </div>
        <div class="gallery-container">
          <Gallery :datasource="gallery.slice(2)"  ref="child2" @childchange="child2change"></Gallery>
        </div>
        <footer class="more">
          <button class="btn dal-btn large" @click="clickab99470a">
            了解更多
          </button>
        </footer>
      </section>

      <!-- /区域三 -->
      <!-- <section class="section3">
        <header>
          <h4>新闻中心</h4>
        </header>
        <main class="news-center-wrapper">
          <div class="news-center-master">
            <img :src="cardnews1.articleImg"
                 @click="clickab278088(cardnews1)" />
          </div>
          <div class="news-center-list">
            <div class="news-center-list-item"
                 v-for="item in articleListnews"
                 :key="item.articleID"
                 @click="clicka3028418(item)">
              <div class="news-center-list-item-cover">
                <img :src="item.articleImg"
                     alt="">
              </div>
              <div class="news-center-list-item-title">
                {{item.articleTitle}}
              </div>
              <div class="news-center-list-item-content"
                   v-html="contentFilter(item.articleContent)">
              </div>
            </div>
          </div>
        </main>
        <footer class="more">
          <button class="btn dal-btn large"
                  @click="clicka2316007">了解更多</button>
        </footer>
      </section> -->
    </main>
    <!-- E内容区 -->

    <!-- S左侧悬浮项 -->
    <div id="d0d6a583">
      <dal-compfloat id="cmpd0d6a583"> </dal-compfloat>
    </div>
    <!-- E左侧悬浮项 -->
  </div>
</template>
<script>
//此文件根据设计自动生成编写框架，请在指定位置编写逻辑代码，如发现框架需要调整的情形请联系:alex.c@daline.com.cn
//在此注释下方添加自定义import
import Gallery from "@/components/Gallery";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper-bundle.min.css";
import {
  DALINEWEB_ARTICLECENTER_V0X0X1_getArticlewithToped,
  DALINEWEB_IMGCENTER_V0X0X1_getImgList,
} from "../api/dalapi.js";
//在此注释下方添加自定义函数
//此注释下方为自动引用部分，请不要修改
import dal_compfloat from "../components/dal-compfloat";
// import dalCompCarousel from "../components/dal-comp-carousel.vue";
import NavSwiper from "../components/nav_swiper";
// import { guestLogin } from "../api/login.js";

export default {
  filters: {
    //限制文本显示字数,超出部分用...代替
    contentFilter(value) {
      if (!value) return "";
      if (value.length > 10) {
        return value.slice(0, 10) + "..";
      }
      return value;
    },
  },
  components: {
    "dal-compfloat": dal_compfloat,
    // dalCompCarousel,
    Swiper,
    SwiperSlide,
    NavSwiper,
    Gallery,
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: "auto",
        spaceBetween: 30,
      },
      //在此注释下方添加自定义变量
      articleList: [],
      articleList1: [],
      card1: "",
      dataList: [
        "https://i1.mifile.cn/a4/xmad_15535933141925_ulkYv.jpg",
        "https://i1.mifile.cn/a4/xmad_15532384207972_iJXSx.jpg",
        "https://i1.mifile.cn/a4/xmad_15517939170939_oiXCK.jpg",
      ],
      currentIndex: 0, //默认显示图片
      timer: null, //定时器
      cardnews1: "",
      articleListIntegrated: [],
      articleListnews: [],

      // 区域一
      section1: {
        carousels: [
          {
            title: "私域流量建设",
            navgation: this.clicka1c45f0c,
            articleList: [],
          },
          {
            title: "数字资产化",
            navgation: this.clickaaa4224a,
            articleList: [],
          },
        ],
      },

      // 区域二
      // section2: {
      //   carousels: [],
      //   activeCarousel: 0,
      // },
      gallery: [
      ],
    };
  },
  mounted() {
    console.log("0301欢迎加入数字智能，与我们共同成长！你可以发送简历到alex.c@daline.com.cn");
    this.IndexImg();
    this.refreshPage();
    this.refreshPage1();
    this.getfourArticle();

  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
    navCarousels() {
      return this.dataList.map((p) => p.imgURL);
    },
    prevIndex() {
      if (this.currentIndex === 0) {
        return this.dataList.length - 1;
      } else {
        return this.currentIndex - 1;
      }
    },
    //下一张
    nextIndex() {
      if (this.currentIndex === this.dataList.length - 1) {
        return 0;
      } else {
        return this.currentIndex + 1;
      }
    },
  },
  watch: {},
  methods: {
    clickCarousel(i,data) {
      // TODO: 点击轮播图后返回当前轮播图的索引，这里写跳转页面的逻辑
      console.log("ddddddd",i,data);
      this.gotoPage(i);
    },
    async clicka8e7ac38(item) {
      this.$router.push({
        path: "article",
        query: {
          articleID: item.articleID,
          articleType: item.articleType,
        },
      });
    },
    gotoPage(index) {
      const id = Number(this.dataList[index].jumpPage);
      if (id < 5) {
        switch (id) {
          case 2:
            this.$router.push("/Privatetrafficconstruction");
            break;
          case 3:
            this.$router.push("/Digitalcapitalization");
            break;
          case 4:
            this.$router.push("/Integratedbusinessdevelopmentplatform");
            break;
        }
      } else {
        this.$router.push({
          path: "/article",
          query: { articleID: id },
        });
      }

      // this.currentIndex = index;
      // clearInterval(this.timer)
    },
    //定时器
    // runInv () {
    //   this.timer = setInterval(() => {
    //     this.gotoPage(this.nextIndex)
    //   }, 6000)
    // },

    clicktoPage(data) {
      let jumpPage = data.jumpPage;
      if (jumpPage == 1) {
        this.$router.push("Privatetrafficconstruction");
      }
      if (jumpPage == 2) {
        this.$router.push("Digitalcapitalization");
      }
      if (jumpPage == 3) {
        this.$router.push("Integratedbusinessdevelopmentplatform");
      }
      if (jumpPage > 3) {
        this.$router.push({
          path: "article",
          query: {
            articleID: jumpPage,
          },
        });
      }
    },
    async clicka1c45f0c() {
      this.$router.push("Privatetrafficconstruction");
    },
    async clickaaa4224a() {
      this.$router.push("Digitalcapitalization");
    },
    async clicka0c27048(item) {
      this.$router.push({
        path: "article",
        query: {
          articleID: item.articleID,
        },
      });
    },
    async clicka6fa5688(item) {
      this.$router.push({
        path: "article",
        query: {
          articleID: item.articleID,
        },
      });
    },
    async clicka2525fdb(item) {
      this.$router.push({
        path: "article",
        query: {
          articleID: item.articleID,
        },
      });
    },
    async clickaa2577a4() {},
    async clicka4b4a6d0() {},
    async clicka8b29e53() {},
    async clickd20b9386() {},
    async clicka92fa56c(item) {
      // this.$router.push('Integratedbusinessdevelopmentplatform')
      this.$router.push({
        path: "article",
        query: {
          articleID: item.articleID,
        },
      });
    },
    async clickab99470a() {
      this.$router.push("Integratedbusinessdevelopmentplatform");
    },
    async clickab278088(item) {
      this.$router.push({
        path: "article",
        query: {
          articleID: item.articleID,
        },
      });
    },
    async clicka3028418(item) {
      this.$router.push({
        path: "article",
        query: {
          articleID: item.articleID,
        },
      });
    },
    async clickac55698a(item) {
      this.$router.push({
        path: "article",
        query: {
          articleID: item.articleID,
        },
      });
    },
    async clicka46463bf(item) {
      this.$router.push({
        path: "article",
        query: {
          articleID: item.articleID,
        },
      });
    },
    async clicka2316007() {
      this.$router.push("Newscenter");
    },
    //在此注释下方添加自定义函数或事件
    async IndexImg() {
      const resImgList = await DALINEWEB_IMGCENTER_V0X0X1_getImgList({
        imgType: 5,
      });
      const imgdataList = resImgList.content;
      this.dataList = imgdataList.imgList;
    },
    // 私域流量建设
    async refreshPage() {
      let dicInput = {
        ArticleType: 167,
        records: 3,
        sort_rules: "preview",
      };
      dicInput.status = "0";
      const res = await DALINEWEB_ARTICLECENTER_V0X0X1_getArticlewithToped(
        dicInput
      );
      const articleList = res.content.resContent;
      // console.log('articleList', articleList)
      this.articleList = articleList;
      this.section1.carousels[0].articleList = articleList;
    },
    // async freshData(res) {

    // },
    // 数字资产化
    async refreshPage1() {
      let dicInput = {
        ArticleType: 169,
        records: 3,
        sort_rules: "preview",
      };
      dicInput.status = "0";
      const res = await DALINEWEB_ARTICLECENTER_V0X0X1_getArticlewithToped(
        dicInput
      );
      const articleList1 = res.content.resContent;
      this.articleList1 = articleList1;
      this.section1.carousels[1].articleList = articleList1;
    },

    contentFilter(value) {
      if (!value) return "";
      if (value.length > 25) {
        return value.slice(0, 25) + ".....";
      }
      return value;
    },
    // 新闻中心
    // async getmostStarArticle () {
    //   let dicInput = {
    //     ofield: "insertTime",
    //     sort: "",
    //     ArticleType: -2,
    //     records: 4,
    //     sort_rules: "preview",
    //     status: 0
    //   };
    //   const res = await DALINEWEB_ARTICLECENTER_V0X0X1_getArticlewithToped(
    //     dicInput
    //   );
    //   const data = JSON.parse(JSON.stringify(res.retContent))
    //   const stararticle = JSON.parse(data.retContent)
    //   // console.log('新闻中心', stararticle)
    //   const list = stararticle.articleList
    //   this.cardnews1 = list[0]
    //   this.articleListnews = list.splice(1)
    // },

    // 一体化业务开发平台
    child1change(){
        console.log('1change');
        this.$refs.child2.parentclickme();
    },
    child2change(){
        console.log('2change');
        this.$refs.child1.parentclickme();
    },
    async getfourArticle() {
      let dicInput = {
        ArticleType: 41,
        records: 10,
        sort_rules: "preview",
      };
      const res = await DALINEWEB_ARTICLECENTER_V0X0X1_getArticlewithToped(
        dicInput
      );

      const list = res.content.resContent;
      if (list.length > 0) {
        this.card1 = list[0];
        this.articleListIntegrated = list.splice(0);
        console.log(this.articleListIntegrated);
        // this.section2.carousels = this.articleListIntegrated;
        for (let index = 0; index < this.articleListIntegrated.length; index++) {
          this.articleListIntegrated[index]['title'] = this.articleListIntegrated[index]['articleTitle'];
          this.articleListIntegrated[index]['keyword1'] = "";
          this.articleListIntegrated[index]['keyword2'] = this.articleListIntegrated[index]['articleSummary'];
          this.articleListIntegrated[index]['img'] = this.articleListIntegrated[index]['articleImg'];
        }
        this.gallery = this.articleListIntegrated;
      } else {
        console.log("none");
      }
      await this.child1change()
    },
  },
};
</script>
<style lang="less" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.ellipsis-3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
#Index {
  background: #ecf0f1;
}
.swiper-slide {
  height: auto;
  width: 80%;
}

#section() {
  .wrapper {
    & > * + * {
      margin-top: 1rem;
    }
    padding: 0 2rem;
  }
}

#header() {
  .title {
    position: relative;
    padding-bottom: 0.5em;
    margin-bottom: 1em;
    text-align: center;
    font: 500 2em/1.5 "PingFang SC";
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 2em;
      height: 0.15em;
      background: #e74c3c;
    }
  }
}

#footer() {
  .wrapper {
    margin-top: 4em;
    display: flex;
    justify-content: center;
  }
}

#Index {
  & > * + *,
  & > main > * + * {
    margin-top: 4rem;
    padding-bottom: 4rem;
  }
  & > main > header {
    height: 740px;
  }
}
section.section1 {
  min-height: 600px;
  // margin: 0 10vw;
  padding: 4em 0 2em;
  border-radius: 0.2em;
  // background: #ecf0f1;
  overflow: auto;
  .swiper-slide {
    width: 80%;
    background: #fff;
    box-shadow: 0 2px 5px #eee;
    border-radius: 0.2em;
    // margin-right: 120px !important;
    &:first-child {
      margin-left: 10vw;
    }
    &:last-child {
      margin-right: 10vw;
    }
  }
  .swiper-container {
    // margin-left: 10vw;
    .carousel-wrapper {
      position: relative;
      .arrow_button_left,
      .arrow_button_right {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        // display: flex;
        // justify-content: center;
        // align-items: center;
        // width: 2em;
        // height: 2em;
        // border-radius: 50%;
        font-size: calc(3vw + 0.75rem);
        cursor: pointer;
        color: #80849b;
        // background: #000;
      }
      .arrow_button_left {
        left: -2em;
      }
      .arrow_button_right {
        right: -2em;
      }
    }
  }
  .carousel-wrapper {
    box-sizing: border-box;
    margin: 2em 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    .carousel-title {
      #header .title();
    }
    ul.article-list {
      // padding: 0 15vw;
      flex: 1;
      display: flex;
      li.article-item {
        &:first-child {
          padding-left: 4em;
        }
        &:last-child {
          padding-right: 4em;
        }
        cursor: pointer;
        padding: 1em;
        flex: 1;
        // height: 300px;
        // text-align: center;
        & > * + * {
          margin-top: 1rem;
        }
        img {
          // object-fit: cover;
          width: 100%;
          border-radius: 2em;
          // height: 300px;
        }
        .article-title {
          margin-top: 1em;
          font: bold 1.5em/1.2 "PingFang SC";
          text-align: center;
        }
        .article-content {
          min-height: 50px;
          font-size: 1.2em;
          line-height: 1.5em;
          color: #636363;
          &:extend(.ellipsis-3);
        }
      }
    }
    .more-article {
      // margin-top: 3em;
      margin-bottom: 3em;
      font-size: 1.5em;
      // padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        padding: 0.5em 2em;
        font-size: 1.25em;
      }
    }
  }
}

section.section2 {
  #section > .wrapper();
  header h4 {
    #header > .title();
  }
  main {
    // height: 800px;
    position: relative;
    img {
      object-fit: cover;
    }
    .checked-carousel {
      position: absolute;
      z-index: 10;
      left: 20%;
      width: 20%;
      height: 800px;
      &-list {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &-item {
          position: relative;
          flex: 1;
          overflow: hidden;
          width: 100%;
          margin: 0.5em 0;
          transition: transform 0.25s ease-in;
          img {
            width: 100%;
            height: 100%;
          }
          .overlay {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
            background-color: rgba(0, 0, 0, 0.5); /*暗淡的背景*/
          }
        }
        .active {
          width: 110%;
          // transform: scale(1.05);
          // transform-origin: 50% 50%;
        }
      }
    }
    .carousel-wrapper {
      z-index: 1;
      top: 0;
      position: absolute;
      width: 100%;
      .el-carousel__item {
        opacity: 0.9;
        img {
          object-fit: cover;
        }
      }
      .el-carousel__item--card {
        width: 100%;
      }
      .is-active {
        // transform+_: scale(1.1);
        // transform-origin: 50% 50%;
        opacity: 1;
      }
    }
  }
  footer {
    font-size: 1.5em;
    #footer > .wrapper();
    button {
      padding: 0.5em 2em;
      font-size: 1.25em;
    }
  }
}

section.section3 {
  padding: 0 15vw;
  // padding: 0 1rem 2rem;
  & > * + * {
    margin-top: 3rem;
  }
  header h4 {
    #header > .title();
  }
  main.news-center-wrapper {
    display: flex;
    height: 600px;
    .news-center-master {
      position: relative;
      width: 50%;
      img {
        object-fit: cover;
        // padding-left: 160px;
      }
    }
    .news-center-list {
      // display: flex;
      justify-content: space-around;
      flex: 1;
      margin-left: 2em;
      &-item {
        display: flex;
        flex-direction: column;
        width: 48%;
        height: 31%;
        padding-top: 4%;
        &-cover {
          width: 100%;
          height: 70%;
          img {
            object-fit: cover;
          }
        }
        &-title {
          height: 10%;
          font-size: 1.2em;
        }
        &-content {
          font-size: 0.9em;
          height: 20%;
        }
      }
    }
    .news-center-list::after {
      content: "";
      width: 48%;
    }
  }
  footer.more {
    display: flex;
    justify-content: center;
  }
}
/*此部分根据设计自动生成，自定义样式追加到该部分结尾处*/
#a6b0e6bf {
  width: 1920px;
  height: 1840px;
  position: relative;
}

#a6b0e6bf {
  /*background-image:url('../assets/img-a3355829.png');*/
  background-size: cover;
}

#abcd54c9 {
  position: relative;
  margin-bottom: 118px;
}

#abcd54c9 {
  background-color: rgb(248, 248, 248, 1);
  border-radius: 0px 0px 0px 0px;
}

#aadde82f {
}

#a7d25bc7 {
  width: 1400px;
  height: 900px;
  display: inline-block;
  vertical-align: middle;
}

#a7d25bc7 {
  background-color: rgb(255, 255, 255, 1);
  border-radius: 20px 20px 20px 20px;
}

#c3812048 {
  padding-top: 50px;
}

#c3812048 {
  text-align: center;
  color: #1c1c1c;
  font-size: 45px;
  font-family: PingFang SC;
}

#a875c1ee {
  width: 1218px;
  position: relative;
  margin-top: 60px;
  padding-left: 91px;
}

#aaa91c7e {
  width: 350px;
  position: relative;
  margin-left: 32px;
  height: 550px;
  display: inline-block;
  vertical-align: middle;
}

#a8e7ac38 {
  width: 360px;
  height: 273px;
}

#a8e7ac38 {
  cursor: pointer;
}

#a5fdb056 {
  margin-top: 30px;
}

#a5fdb056 {
  text-align: left;
  color: #000;
  font-size: 30px;
  font-family: PingFang SC;
}

#a7164a9b {
  width: 302px;
  position: relative;
  margin-top: 10px;
  padding-left: 28px;
}

#a6dfff29 {
  width: 302px;
}

#a6dfff29 {
  text-align: left;
  color: #727272;
  font-size: 25px;
  font-family: PingFang SC;
}

#a5d6ffe6 {
  width: 385px;
  position: relative;
  margin-left: 32px;
  display: inline-block;
  vertical-align: middle;
}

#df3f1677 {
  width: 385px;
  height: 273px;
}

#df3f1677 {
  cursor: pointer;
}

#ac145eeb {
  margin-top: 30px;
}

#ac145eeb {
  text-align: left;
  color: #000;
  font-size: 30px;
  font-family: PingFang SC;
}

#ba2d436d {
  width: 302px;
  position: relative;
  margin-top: 10px;
  padding-left: 40px;
}

#ad3d42a0 {
  width: 302px;
}

#ad3d42a0 {
  text-align: left;
  color: #727272;
  font-size: 25px;
  font-family: PingFang SC;
}

#a0d1a160 {
  width: 385px;
  position: relative;
  margin-left: 31px;
  display: inline-block;
  vertical-align: middle;
}

#ab089f6e {
  width: 385px;
  height: 273px;
}

#ab089f6e {
  cursor: pointer;
}

#c8a7bbf3 {
  margin-top: 30px;
}

#c8a7bbf3 {
  text-align: left;
  color: #000;
  font-size: 30px;
  font-family: PingFang SC;
}

#f2a18106 {
  width: 302px;
  position: relative;
  margin-top: 10px;
  padding-left: 42px;
}

#ef015407 {
  width: 302px;
}

#ef015407 {
  text-align: left;
  color: #727272;
  font-size: 25px;
  font-family: PingFang SC;
}

#a1c45f0c {
  width: 299px;
  /* margin-top:20px; */
  margin-left: 552px;
  margin-bottom: 70px;
}

#a1c45f0c {
  background-color: rgb(20, 22, 32, 1);
  border-radius: 10px 10px 10px 10px;
}

#a1c45f0c {
  color: #fff;
  font-size: 40px;
  font-family: PingFang SC;
  border: transparent;
}

#aa07e96c {
  width: 1400px;
  height: 900px;
  /* margin-left:100px; */
  display: inline-block;
  vertical-align: middle;
}

#aa07e96c {
  background-color: rgb(255, 255, 255, 1);
  border-radius: 20px 20px 20px 20px;
}

#a11e63c7 {
  padding-top: 50px;
  /* padding-left: 650px; */
}

#a11e63c7 {
  text-align: center;
  color: #1c1c1c;
  font-size: 45px;
  font-family: PingFang SC;
}

#cae38f82 {
  width: 230px;
  height: 5px;
  margin-top: 10px;
  padding-left: 585px;
}

#cae38f82 {
  vertical-align: middle;
}

#c1fad6a5 {
  width: 1218px;
  position: relative;
  margin-top: 60px;
  padding-left: 91px;
}

#f498b352 {
  width: 350px;
  position: relative;
  margin-left: 42px;
  height: 550px;
  display: inline-block;
  vertical-align: middle;
}

#a0c27048 {
  width: 360px;
  height: 273px;
  /* border-radius: 50%; */
}

#a0c27048 {
  cursor: pointer;
}

#a6e90a59 {
  margin-top: 30px;
}

#a6e90a59 {
  text-align: left;
  color: #000;
  font-size: 30px;
  font-family: PingFang SC;
}

#a3b0e7d9 {
  width: 302px;
  position: relative;
  margin-top: 10px;
}

#a4f4ff8e {
  width: 302px;
}

#a4f4ff8e {
  text-align: left;
  color: #727272;
  font-size: 25px;
  font-family: PingFang SC;
}

#a38f7a02 {
  width: 303px;
  position: relative;
  margin-left: 155px;
  display: inline-block;
  vertical-align: middle;
}

#a6fa5688 {
  width: 302px;
  height: 302px;
  padding-left: 1px;
  border-radius: 50%;
}

#a6fa5688 {
  cursor: pointer;
}

#a23ad3c4 {
  margin-top: 30px;
}

#a23ad3c4 {
  text-align: left;
  color: #000;
  font-size: 30px;
  font-family: PingFang SC;
}

#acdbbcc2 {
  width: 302px;
  position: relative;
  margin-top: 10px;
}

#a46e6efd {
  width: 302px;
}

#a46e6efd {
  text-align: left;
  color: #727272;
  font-size: 25px;
  font-family: PingFang SC;
}

#a868be05 {
  width: 302px;
  position: relative;
  margin-left: 156px;
  display: inline-block;
  vertical-align: middle;
}

#a2525fdb {
  width: 302px;
  height: 302px;
  border-radius: 50%;
}

#a2525fdb {
  cursor: pointer;
}

#a8e51272 {
  margin-top: 30px;
}

#a8e51272 {
  text-align: left;
  color: #000;
  font-size: 30px;
  font-family: PingFang SC;
}

#ada3a1d8 {
  width: 302px;
  position: relative;
  margin-top: 10px;
}

#aa149974 {
  width: 302px;
}

#aa149974 {
  text-align: left;
  color: #727272;
  font-size: 25px;
  font-family: PingFang SC;
}

#aaa4224a {
  width: 299px;
  /* margin-top:20px; */
  margin-left: 552px;
  margin-bottom: 70px;
}

#aaa4224a {
  background-color: rgb(20, 22, 32, 1);
  border-radius: 20px 20px 20px 20px;
}

#aaa4224a {
  color: #fff;
  font-size: 40px;
  font-family: PingFang SC;
  border: transparent;
}

#aff635b4 {
}

#aff635b4 {
  background-image: url("../assets/img-a9e40533.png");
  background-size: cover;
}

#ae14393b {
  padding-top: 20px;
  padding-left: 710px;
  vertical-align: middle;
}

#ae14393b {
  text-align: left;
  color: #000;
  font-size: 45px;
  font-family: PingFang SC;
}

#ce3859f2 {
  /* width:1530px; */
  width: 80%;
  position: relative;
  margin-top: 60px;
  padding-left: 150px;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

#a739905b {
  width: 316px;
  position: relative;
  padding-bottom: 1px;
  display: inline-block;
  vertical-align: middle;
}

#aa2577a4 {
  width: 315px;
  /* height:263px; */
  /* padding-left:1px; */
  position: relative;
  padding-bottom: 1px;
  display: inline-block;
  vertical-align: middle;
}

#aa2577a4 {
  cursor: pointer;
}

#a4b4a6d0 {
  width: 286px;
  height: 170px;
  margin-top: 30px;
}

#a4b4a6d0 {
  cursor: pointer;
}

#a8b29e53 {
  width: 286px;
  height: 170px;
  margin-top: 20px;
}

#a8b29e53 {
  cursor: pointer;
}

#d20b9386 {
  width: 286px;
  height: 170px;
  margin-top: 20px;
}

#d20b9386 {
  cursor: pointer;
}

#a92fa56c {
  width: 1213px;
  height: 844px;
  margin-left: 1px;
  display: inline-block;
  vertical-align: middle;
}

#a92fa56c {
  cursor: pointer;
}

#ab99470a {
  width: 299px;
  margin-top: 40px;
  margin-left: 811px;
  margin-bottom: 50px;
}

#ab99470a {
  background-color: rgb(20, 22, 32, 1);
  border-radius: 10px 10px 10px 10px;
}

#ab99470a {
  color: #fff;
  font-size: 40px;
  font-family: PingFang SC;
  border: transparent;
}

#a2009682 {
  width: 1201px;
  position: relative;
  margin-top: 50px;
  padding-left: 351px;
  padding-bottom: 202px;
}

#a930e1ff {
  color: #000;
  font-size: 45px;
  font-family: PingFang SC;
}

#d7a66698 {
  position: relative;
  margin-top: 61px;
  display: flex;
}

#ab278088 {
  width: 1200px;
  height: 700px;
  display: inline-block;
  vertical-align: middle;
}

#ab278088 {
  cursor: pointer;
}

#e4143226 {
  width: 461px;
  margin-left: 30px;
  padding-bottom: 1px;
  display: inline-block;
  vertical-align: middle;
}

#dc7a8a38 {
  width: 461px;
  position: relative;
}

#a3028418 {
  width: 207px;
  height: 140px;
  display: inline-block;
  vertical-align: middle;
}

#a3028418 {
  cursor: pointer;
}

#a09ead2b {
  width: 234px;
  position: relative;
  margin-left: 20px;
  padding-bottom: 15px;
  display: inline-block;
  vertical-align: middle;
}

#ac3a4fa2 {
}

#ac3a4fa2 {
  text-align: left;
  color: #000;
  font-size: 25px;
  font-family: PingFang SC;
}

#b6f6c7a9 {
  width: 234px;
  position: relative;
  margin-top: 10px;
}

#f90b4e1d {
  text-align: left;
  color: #000;
  font-size: 18px;
  font-family: PingFang SC;
}

#a94bd99d {
  width: 461px;
  position: relative;
  margin-top: 30px;
}

#ac55698a {
  width: 207px;
  height: 140px;
  display: inline-block;
  vertical-align: middle;
}

#ac55698a {
  cursor: pointer;
}

#abf6364d {
  width: 234px;
  position: relative;
  margin-left: 20px;
  padding-bottom: 15px;
  display: inline-block;
  vertical-align: middle;
}

#fe5d464a {
}

#fe5d464a {
  text-align: left;
  color: #000;
  font-size: 25px;
  font-family: PingFang SC;
}

#ae38c99f {
  width: 234px;
  position: relative;
  margin-top: 10px;
}

#a1ca3d85 {
}

#a1ca3d85 {
  text-align: left;
  color: #000;
  font-size: 18px;
  font-family: PingFang SC;
}

#a4a92cef {
  width: 461px;
  position: relative;
  margin-top: 30px;
}

#a46463bf {
  width: 207px;
  height: 140px;
  display: inline-block;
  vertical-align: middle;
}

#a46463bf {
  cursor: pointer;
}

#a7f4e732 {
  width: 234px;
  position: relative;
  margin-left: 20px;
  padding-bottom: 15px;
  display: inline-block;
  vertical-align: middle;
}

#a4816385 {
}

#a4816385 {
  text-align: left;
  color: #000;
  font-size: 25px;
  font-family: PingFang SC;
}

#ab897421 {
  width: 234px;
  position: relative;
  margin-top: 10px;
}

#accf9f63 {
}

#accf9f63 {
  text-align: left;
  color: #000;
  font-size: 18px;
  font-family: PingFang SC;
}

#a2316007 {
  width: 299px;
  margin-top: 150px;
  margin-left: 460px;
}

#a2316007 {
  background-color: rgb(20, 22, 32, 1);
  border-radius: 10px 10px 10px 10px;
}

#a2316007 {
  color: #fff;
  font-size: 40px;
  font-family: PingFang SC;
  border: transparent;
}

#d0d6a583 {
  width: 150px;
  position: fixed;
  z-index: 50;
  top: 2958px;
  left: 1740px;
}

#d0d6a583 {
  background-color: rgb(20, 22, 32, 1);
  border-radius: 5px 5px 5px 5px;
}

/*在此注释下方添加自定义样式*/
#ab278077 {
  width: 1000px;
  height: 600px;
  display: inline-block;
  vertical-align: middle;
}

.banner ul li {
  list-style: none;
  float: left;
  width: 30px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
}

.banner {
  height: 640px;
  position: relative;
}

.banner img {
  width: 100%;
  height: 640px;
  object-fit: cover;
}

.banner .page {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  text-align: center;
  right: 0;
  bottom: 0;
  width: 100%;
}

.banner .page ul {
  /*float: right;*/
}

.banner li.current {
  color: #ff6700;
}

.el-carousel__container {
  /* height: 45px; */
}

// .el-icon-arrow-left {
//   padding-top: 200px;
// }
</style>